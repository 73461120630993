@font-face {
  font-family: Prometo;
  src: url(./fontfamily/Prometo/Prometo-Medium.ttf);
  font-weight: 100;
}
@font-face {
  font-family: Roboto;
  src: url(./fontfamily/Roboto/Roboto-Black.ttf);
  font-weight: 100;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* font-family: Roboto; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  /* font-family: Roboto; */
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.react-pdf__Page__canvas {
  max-height: 700px !important;
  max-width: 550px !important;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  max-height: 700px !important;
  max-width: 550px !important;
  width: 100% !important;
}
