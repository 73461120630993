.profile-pic {
	position: relative;
	display: inline-block;
	z-index: 9999;
}

.profile-pic:hover .edit {
	visibility: visible;
}

.edit {
	padding-top: 0px;
	padding-left: 7px;
	padding-right: 7px;
	position: relative;
	right: 0;
	top: 0;
	width: 40px;
	cursor: pointer;
	visibility: hidden;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.edit a {
	color: #000;
}
